//import IMask from 'imask';
import lightGallery from 'lightgallery';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
//import { Modal } from 'bootstrap/js/dist/modal';

import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

// Инициализация при загрузке DOM-дерева
document.addEventListener('DOMContentLoaded', () => {
  //initTelMasks();
  initLightGalleries();
  //initTemplateSlider();
  initFeedbacks();

  if (document.querySelector('[data-navclose]')) {
    document.querySelectorAll('[data-navclose]').forEach((el) => {
      el.addEventListener('click', closeNav);
    });
  }

  if (document.querySelector('[data-navtoggler]')) {
    document.querySelectorAll('[data-navtoggler]').forEach((el) => {
      el.addEventListener('click', toggleNav);
    });
  }
});

// Функция инициализации масок телефонных номеров
function initTelMasks() {
  const telInputs = document.querySelectorAll('input[type=tel]');
  telInputs.forEach((el) => {
    IMask(el, {
      mask: '+{7} 000 000 00 00',
    });
  });
}

// Функция инициализации галерей изображений
function initLightGalleries() {
  const galleryElements = document.querySelectorAll('[data-gallery]');
  galleryElements.forEach((el) => {
    lightGallery(el, {
      licenseKey: '0000 0000 0000 0000',
      download: false,
      fullScreen: false,
      plugins: [lgFullscreen],
      selector: '[data-src]',
    });
  });
}

function initTemplateSlider() {
  const slider = new Swiper('.slider-template', {
    loop: true,
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 400,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}
function initFeedbacks() {
  const sliderFeedbacks = new Swiper('.slider-feedbacks', {
    loop: false,
    slidesPerView: 1,
    spaceBetween: 100,
    speed: 400,
    autoplay: {
      delay: 8000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
}

// Экспорт модального окна для доступа извне
//window.modal = Modal;

function closeNav() {
  document.querySelector('[data-nav]').classList.remove('active');
}

function toggleNav() {
  document.querySelector('[data-nav]').classList.toggle('active');
}
